import { Link, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { Check, X } from 'react-feather'

import YelpIcon from '../assets/icons/yelp'
import FacebookIcon from '../assets/icons/facebook'
import InstagramIcon from '../assets/icons/instagram'
import GoogleIcon from '../assets/icons/google'
import YoutubeIcon from '../assets/icons/youtube'
import GuildqualityIcon from '../assets/icons/guildquality'

import FooterAreasServedList from './footer-areas-served'

const LegalLinks = styled.div`
  margin-top: 5px;
  a {
    color: #989898;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 0.9em;
    &:hover {
      color: #fff;
    }
  }
`
const AreasServedUl = styled.ul`
  display: flex;
  flex-wrap: wrap;
  li {
    flex: 0 0 50%;
  }
`
const FooterOuterContainer = styled.div`
  background: #fff;
  padding: 4em 0;
  font-size: 0.9em;
`
const CopyrightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid rgba(255, 255, 255, 0.09);
  padding-top: 1em;
`
const CopyrightTextContainer = styled.div`
  color: #757575;
  text-align: center;
  margin: 0;
  padding: 0.5em;
  font-size: 0.8em;
`

const CopyrightText = styled.p``
const SidebarHeader = styled.h4`
  margin-bottom: 0.5em;
`
const SidebarP = styled.p`
  color: #444;
`
const FooterLinksMenu = styled.ul``

const WhyUsUnorderedList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: relative;
  li {
    list-style-type: none;
    padding: 0;
    padding-left: 25px;
    line-height: 1.4;
    position: relative;
    svg {
      border-radius: 50%;
      padding-top: 5px;
      stroke-width: 5px;
      margin-left: -30px;
      padding: 4px;
      position: absolute;
      top: 2px;
    }
  }
`

const SocialMediaIcon = styled.div`
  path {
    fill: #444;
  }
  a {
    svg {
      height: 24px;
    }
  }
`

const SocialMediaIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 300px;
  margin: 0 auto;
  padding: 1em 0;
`

const CompanyInfoColumn = props => {
  let { siteMetadata } = props
  return (
    <>
      {siteMetadata.companyInfo.license ? (
        <div>
          <SidebarHeader>CSLB Licensed Contractor</SidebarHeader>
          <SidebarP>CA License #: {siteMetadata.companyInfo.license}</SidebarP>
        </div>
      ) : null}

      <SidebarHeader>Contact Us</SidebarHeader>
      <SidebarP>{siteMetadata.companyInfo.phone}</SidebarP>

      <SidebarHeader>Office/Showroom Location</SidebarHeader>
      <SidebarP>
        {siteMetadata.companyInfo.addressLine1}
        <br />
        {siteMetadata.companyInfo.city}, {siteMetadata.companyInfo.state}{' '}
        {siteMetadata.companyInfo.zip}
      </SidebarP>
    </>
  )
}

const WhyUs = props => {
  return (
    <>
      <h4>Why Us</h4>
      <WhyUsUnorderedList>
        {props.siteMetadata.whyUs.map((item, index) => (
          <li key={index}>
            <Check size={22} className="text-color-primary" />
            {item}
          </li>
        ))}
      </WhyUsUnorderedList>
    </>
  )
}

const AreasServed = props => {
  let { serviceArea } = props
  return (
    <>
      <h4>Areas Served</h4>
      <AreasServedUl>
        {serviceArea.edges.map((item, index) => (
          <li key={index}>
            <Link
              to={'/cities/' + item.node.slug + '/'}
              title={item.node.location + ' Remodeling Contractor'}
            >
              {item.node.location}
            </Link>
          </li>
        ))}
      </AreasServedUl>
      <Link to={'/cities/'}>
        <i>View Complete Service Area</i>
      </Link>
    </>
  )
}
const FooterColumms = footerData => {
  const footerColumns = footerData.data.map((footer, index) => (
    <FooterColumm
      key={index}
      footerInfo={footer}
      columnsCount={footerData.data.length}
      siteData={footerData.siteData.site}
      serviceArea={footerData.siteData.allContentfulServiceArea}
    />
  ))
  return footerColumns
}

const FooterColumm = footerInfo => {
  if (
    footerInfo.footerInfo.node &&
    footerInfo.footerInfo.node.html &&
    footerInfo.footerInfo.node.html.html.includes('{{CompanyInfo}}')
  ) {
    return (
      <div
        className={'mb-4 col-12 col-md-' + 12 / footerInfo.columnsCount}
        style={{
          order:
            footerInfo.footerInfo &&
            footerInfo.footerInfo.node &&
            footerInfo.footerInfo.node.order,
        }}
      >
        <CompanyInfoColumn siteMetadata={footerInfo.siteData.siteMetadata} />
      </div>
    )
  }
  if (
    footerInfo.footerInfo.node &&
    footerInfo.footerInfo.node.html &&
    footerInfo.footerInfo.node.html.html.includes('{{WhyUs}}')
  ) {
    return (
      <div
        className={'mb-4 col-12 col-md-' + 12 / footerInfo.columnsCount}
        style={{
          order:
            footerInfo.footerInfo &&
            footerInfo.footerInfo.node &&
            footerInfo.footerInfo.node.order,
        }}
      >
        <WhyUs siteMetadata={footerInfo.siteData.siteMetadata} />
      </div>
    )
  }
  if (
    footerInfo.footerInfo.node &&
    footerInfo.footerInfo.node.html &&
    footerInfo.footerInfo.node.html.html.includes('{{AreasServed}}')
  ) {
    return (
      <div
        className={'mb-4 col-12 col-md-' + 12 / footerInfo.columnsCount}
        style={{
          order:
            footerInfo.footerInfo &&
            footerInfo.footerInfo.node &&
            footerInfo.footerInfo.node.order,
        }}
      >
        <AreasServed serviceArea={footerInfo.serviceArea} />
      </div>
    )
  }

  if (footerInfo.footerInfo.node.menuLinks) {
    const footerLinksMapped = footerInfo.footerInfo.node.menuLinks.map(
      (menuItem, index) => (
        <li key={index}>
          <Link
            to={menuItem.url ? menuItem.url : '#'}
            title={menuItem.title}
            activeClassName={'active'}
          >
            {menuItem.title}
          </Link>
        </li>
      )
    )
    return (
      <div
        className={'mb-4 col-12 col-md-' + 12 / footerInfo.columnsCount}
        style={{
          order:
            footerInfo.footerInfo &&
            footerInfo.footerInfo.node &&
            footerInfo.footerInfo.node.order,
        }}
      >
        <h4>{footerInfo.footerInfo.node.title}</h4>
        <FooterLinksMenu>{footerLinksMapped}</FooterLinksMenu>
      </div>
    )
  }

  return (
    <div
      className={'mb-4 col-12 col-md-' + 12 / footerInfo.columnsCount}
      style={{
        order:
          footerInfo.footerInfo &&
          footerInfo.footerInfo.node &&
          footerInfo.footerInfo.node.order,
      }}
    >
      <h4>{footerInfo.footerInfo.node.title}</h4>test
      {footerInfo.footerInfo.node &&
      footerInfo.footerInfo.node.html &&
      footerInfo.footerInfo.node.html.html ? (
        <div
          dangerouslySetInnerHTML={{
            __html: footerInfo.footerInfo.node.html.html,
          }}
        />
      ) : (
        ''
      )}
    </div>
  )
}

const Footer = ({ isHomePage = false }) => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      site {
        siteMetadata {
          title
          whyUs
          companyInfo {
            phone
            email
            addressLine1
            addressLine2
            city
            state
            zip
            license
          }
          social {
            yelp
            facebook
            instagram
            youtube
            google
            guildquality
          }
        }
      }
      allContentfulFooterFooterColumn {
        edges {
          node {
            id
            title
            order
            menuLinks {
              id
              name
              title
              url
              openInNewWindow
              submenu {
                id
                name
                menuItems {
                  id
                  name
                  title
                  url
                  openInNewWindow
                }
              }
            }
            html {
              html
            }
          }
        }
      }
      allContentfulServiceArea(
        filter: { featuredCity: { eq: true } }
        sort: { fields: location, order: ASC }
      ) {
        edges {
          node {
            location
            id
            slug
          }
        }
      }
      remodelingCitiesQuery: allContentfulServiceArea(
        sort: { fields: location, order: ASC }
      ) {
        edges {
          node {
            location
            slug
          }
        }
      }
    }
  `)

  //assuming length of array equals columns intended
  let footerData = data.allContentfulFooterFooterColumn.edges

  let { siteMetadata } = data.site

  const areasServedPopupOpenRef = useRef()
  const [areasServedPopupOpen, setAreasServedPopupOpen] = useState(false)
  const toggleReviewsModal = state => {
    setAreasServedPopupOpen(state)
  }

  useEffect(() => {
    if (areasServedPopupOpen) {
      document.body.classList.add('modal-open')
    } else {
      document.body.classList.remove('modal-open')
    }
  }, [areasServedPopupOpen])

  useEffect(() => {
    const handleEsc = event => {
      if (event.keyCode === 27) {
        toggleReviewsModal(false)
      }
    }
    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [])

  function useOnClickOutside(ref, handler) {
    useEffect(() => {
      const listener = event => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }

        handler(event)
      }

      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)

      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    }, [ref, handler])
  }

  useOnClickOutside(areasServedPopupOpenRef, () => toggleReviewsModal(false))

  return (
    <FooterOuterContainer className="container-fluid bg-color-primary-dark footer-theme-dark">
      <div
        className={`modal-bg-overlay ${areasServedPopupOpen ? 'active' : null}`}
      />
      <div
        className={` overflow-y-scroll  h-full standard-modal ${
          areasServedPopupOpen ? 'active' : null
        }`}
        ref={areasServedPopupOpenRef}
      >
        <div className="flex items-center justify-between px-8 py-6">
          <div className="font-semibold text-black text-2xl">Areas Served</div>
          <button
            type="button"
            onClick={() => toggleReviewsModal(!areasServedPopupOpen)}
            className="border-0 bg-white hover:gvd-main-bg-darker cursor-pointer rounded-full w-10 h-10 flex text-black items-center justify-center"
          >
            {' '}
            <X size={28} />
          </button>
        </div>
        <div className="px-8">
          <FooterAreasServedList />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <FooterColumms data={footerData} siteData={data} />
        </div>
      </div>

      <SocialMediaIcons>
        {siteMetadata.social.yelp ? (
          <SocialMediaIcon>
            <a
              href={siteMetadata.social.yelp}
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              <YelpIcon />
            </a>
          </SocialMediaIcon>
        ) : (
          ''
        )}

        {siteMetadata.social.facebook ? (
          <SocialMediaIcon>
            <a
              href={siteMetadata.social.facebook}
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              <FacebookIcon />
            </a>
          </SocialMediaIcon>
        ) : (
          ''
        )}

        {siteMetadata.social.google ? (
          <SocialMediaIcon>
            <a
              href={siteMetadata.social.google}
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              <GoogleIcon />
            </a>
          </SocialMediaIcon>
        ) : (
          ''
        )}

        {siteMetadata.social.guildquality ? (
          <SocialMediaIcon>
            <a
              href={siteMetadata.social.guildquality}
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              <GuildqualityIcon />
            </a>
          </SocialMediaIcon>
        ) : (
          ''
        )}
        {siteMetadata.social.youtube ? (
          <SocialMediaIcon>
            <a
              href={siteMetadata.social.youtube}
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              <YoutubeIcon />
            </a>
          </SocialMediaIcon>
        ) : (
          ''
        )}

        {siteMetadata.social.instagram ? (
          <SocialMediaIcon>
            <a
              href={siteMetadata.social.instagram}
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              <InstagramIcon />
            </a>
          </SocialMediaIcon>
        ) : (
          ''
        )}
      </SocialMediaIcons>

      <CopyrightContainer>
        <CopyrightTextContainer>
          <CopyrightText>
            Full Service, One Stop Shop - Sacramento Home Remodeling Experts
            <br />
            &copy; {new Date().getFullYear()} {data.site.siteMetadata.title},
            Inc. All Rights Reserved. <br />
            Serving Sacramento, CA 95841 and surrounding areas. CA Contractor
            Lic. #975441
            <br />
            <span
              itemScope
              className="rating-desc"
              itemType="https://schema.org/Product"
            >
              <span itemProp="name">{data.site.siteMetadata.title}</span>
              <span
                itemScope
                itemProp="aggregateRating"
                itemType="https://schema.org/AggregateRating"
              >
                {' '}
                rated <span itemProp="ratingValue">4.9</span> / 5 based on{' '}
                <span itemProp="reviewCount">157</span> reviews.
              </span>
            </span>{' '}
            {isHomePage ? (
              <div>
                <span>
                  {' '}
                  Web Design &amp; Marketing by{' '}
                  <a
                    target="_blank"
                    href="https://www.webperfex.com/?utm_source=solidconstructiondesign&utm_medium=referral"
                    style={{ color: 'rgb(136, 136, 136)' }}
                  >
                    WebPerfex
                  </a>
                </span>
              </div>
            ) : null}
          </CopyrightText>
          <LegalLinks>
            <Link
              to={'/privacy-policy/'}
              title={'Learn about our commitment to privacy'}
            >
              Privacy Policy
            </Link>
            &nbsp;
            <Link to={'/contact/'} title={'Contact Us'}>
              Contact Us
            </Link>
          </LegalLinks>
        </CopyrightTextContainer>
      </CopyrightContainer>
      <div class="container">
        <CopyrightContainer>
          <CopyrightText className="footercities">
            Solid Construction and Design Proudly Serves all of Greater{' '}
            <Link
              to={'/'}
              title={'Kitchen Remodeling Company Contractor Sacramento CA'}
            >
              Sacramento
            </Link>{' '}
            and surrounding cities including &nbsp;
            {data.remodelingCitiesQuery.edges.map(
              ({ node: { id, location, slug } }, i) => (
                <React.Fragment key={id}>
                  <Link
                    to={`/cities/${slug}/kitchen-remodeling/`}
                    title={`Kitchen Remodeling ${location} CA`}
                  >
                    {location}
                  </Link>
                  {(i < data.remodelingCitiesQuery.edges.length - 2
                    ? ', '
                    : '') +
                    (i == data.remodelingCitiesQuery.edges.length - 2
                      ? ' and '
                      : '')}
                </React.Fragment>
              )
            )}{' '}
            <span
              className="cursor-pointer hover:text-white"
              onClick={() => toggleReviewsModal(!areasServedPopupOpen)}
            >
              - See All Service Area
            </span>
          </CopyrightText>
        </CopyrightContainer>
      </div>
    </FooterOuterContainer>
  )
}

export default Footer
