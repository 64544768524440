import React, { useState, useEffect } from 'react'
import { Link, navigate } from 'gatsby'
import Button from '../components/button'
import InputMask from 'react-input-mask'
import { AlertTriangle } from 'react-feather'


function EstimateForm({
  style,
  showLabels,
  pageTitle,
  pageProps,
  pageLocation,
  showPlaceHolders,
  dark,
}) {
  const [spamFilter, updateSpamFilterStatus] = useState(false)
  const [sessionSource, setSessionSource] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  useEffect(() => {
    const referrer = document.referrer
    if (referrer) {
      setSessionSource(referrer)
      localStorage.setItem('sessionSource', referrer)
    } else {
      const storedSource = localStorage.getItem('sessionSource')
      if (storedSource) {
        setSessionSource(storedSource)
      } else {
        const initialSource = new URLSearchParams(window.location.search).get('utm_source') || 'direct'
        localStorage.setItem('sessionSource', initialSource)
        setSessionSource(initialSource)
      }
    }
  }, [])

  function handleSubmit(e) {
    e.preventDefault()
    if (isSubmitting) return
    if (
      phone.includes('(171') ||
      phone.includes('(191') ||
      phone.includes('(121')
    ) {
      updateSpamFilterStatus(true)
      return false
    }
    setIsSubmitting(true)
    console.log('Submit')
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'free-estimate-form',
        subject: `SOLIDCD Form Submission - ${firstName} ${lastName}`,
        firstname: firstName,
        lastname: lastName,
        url: url,
        landingURL: landingURL,
        email: email,
        phone: phone,
        description: description,
        referrer: referrer,
        ['bot-field']: botField,
        sessionSource: sessionSource,
      }),
    })
      .then(() =>
        navigate('/thanks', {
          state: { firstname: firstName, lastName: lastName, email: 'test' },
        })
      )
      .catch(error => {
        alert(error)
        setIsSubmitting(false)
      })
  }

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [url, setUrl] = useState(pageLocation)
  const [landingURL, setLandingURL] = useState(
    typeof window !== 'undefined' &&
      window.localStorage &&
      window.localStorage.getItem('landingURL')
      ? window.localStorage.getItem('landingURL')
      : 'Not landing URL found'
  )
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [description, setDescription] = useState('')
  const [referrer, setReferrer] = useState('')
  const [botField, setBotField] = useState('')

  return (
    <form
      onSubmit={e => handleSubmit(e)}
      name="free-estimate-form"
      method="post"
      action="/success"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      className={
        style
          ? style + ' mt-4 mb-0 mx-auto'
          : 'contact-form-default-style mt-4 mb-0 mx-auto'
      }
    >
      <input
        type="hidden"
        name="bot-field"
        value={botField}
        onChange={e => setBotField(e.target.value)}
      />

      <input type="hidden" name="form-name" value="free-estimate-form" />
      <input type="hidden" name="firstname" />
      <input type="hidden" name="lastname" />
      <input type="hidden" name="email" />
      <input type="hidden" name="phone" />
      <input type="hidden" name="url" />
      <input type="hidden" name="landingURL" />
      <input type="hidden" name="description" />
      <input type="hidden" name="referrer" value={referrer} />
      <input type="hidden" name="sessionSource" value={sessionSource} />
      <input type="hidden" id="subject" name="subject" />

      <div className="grid grid-cols-1 row-gap-3 col-gap-4 sm:grid-cols-6">
        <div className="sm:col-span-3">
          <label
            htmlFor="firstname"
            className={`block text-base font-medium leading-5 text-gray-800 ${
              !showLabels ? 'sr-only' : ''
            }`}
          >
            First Name
          </label>
          <div className="mt-1">
            <input
              id="firstname"
              type="text"
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
              placeholder={showPlaceHolders ? 'First Name' : ''}
              className="form-control  "
              required={true}
            />
          </div>
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="lastname"
            className={`block text-base font-medium leading-5 text-gray-800 ${
              !showLabels ? 'sr-only' : ''
            }`}
          >
            Last Name
          </label>
          <div className="mt-1">
            <input
              id="lastname"
              type="text"
              value={lastName}
              onChange={e => setLastName(e.target.value)}
              className="form-control"
              placeholder={showPlaceHolders ? 'Last Name' : ''}
              required={true}
            />
          </div>
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="email"
            className={`block text-base font-medium leading-5 text-gray-800 ${
              !showLabels ? 'sr-only' : ''
            }`}
          >
            Email address
          </label>
          <div className="mt-1">
            <input
              type="email"
              name="email"
              id="email"
              value={email}
              placeholder={showPlaceHolders ? 'Email' : ''}
              required={true}
              onChange={e => {
                setEmail(e.target.value)
                if (
                  e.target.value.match(
                    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
                  ) == null
                ) {
                  e.target.setCustomValidity(
                    'Please enter a valid domain at the end of your email address'
                  )
                } else {
                  e.target.setCustomValidity('')
                }
              }}
              className="form-control "
            />
          </div>
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="phone"
            className={`block text-base font-medium leading-5 text-gray-800 ${
              !showLabels ? 'sr-only' : ''
            }`}
          >
            Phone
          </label>
          <div className="mt-1">
            <InputMask
              type="tel"
              name="phone"
              id="phone"
              placeholder={showPlaceHolders ? 'Phone' : ''}
              className="form-control "
              mask="(999) 999-9999"
              onChange={e => {
                setPhone(e.target.value)
                if (
                  e.target.value.match(
                    /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/
                  ) == null
                ) {
                  e.target.setCustomValidity(
                    'Please enter a complete phone number'
                  )
                } else {
                  e.target.setCustomValidity('')
                }
              }}
              required
            />
          </div>
        </div>

        <div className="sm:col-span-6">
          <label
            htmlFor="description"
            className={`block text-base font-medium leading-5 text-gray-800 ${
              !showLabels ? 'sr-only' : ''
            }`}
          >
            Tell us about your project
          </label>
          <div className="mt-1">
            <textarea
              id="description"
              type="text"
              rows="4"
              value={description}
              placeholder={showPlaceHolders ? 'Tell us about your project' : ''}
              onChange={e => setDescription(e.target.value)}
              className=" form-control "
            ></textarea>
          </div>{' '}
        </div>
      </div>

      <div className="border-t border-gray-200 mt-3 w-full">
        {spamFilter && (
          <div className="font-bold bg-red-600 p-2 px-4 mb-2 text-white">
            An Error Occured. Give Us A Call.
          </div>
        )}
        <Button
          role="submit"
          type="submit"
          color="primary"
          className="shadow-lg w-full"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Submitting...' : 'Get FREE Estimate →'}
        </Button>
      </div>
      <div
        className={[
          'flex items-center justify-center mt-2 formdisclosure',
          dark ? 'text-white' : 'text-gray-700',
        ].join(' ')}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="w-4 h-4 mr-1"
          style={dark ? null : { color: '#4b5767' }}
        >
          <path
            fillRule="evenodd"
            d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
            clipRule="evenodd"
          />
        </svg>
        <span className={dark ? 'text-white' : null}>
          *Free estimates for residential homeowners only. Real estate
          evaluations/inspections are billable.
        </span>
      </div>
    </form>
  )
}

export default EstimateForm
