import { Link } from 'gatsby'
import React from 'react'

class Button extends React.Component {
  render() {
    return this.props.to ? (
      <Link
        title={
          this.props && this.props.title
            ? this.props.title
            : this.props.children
        }
        to={this.props.to}
        className={
          `button-outer-link ${this.props.hasArrow ? 'w-arrow ' : ''}` +
          'button-style-primary ' +
          'button-style-' +
          this.props.color +
          (this.props.className ? ' ' + this.props.className : '')
        }
      >
        {this.props.children ? (
          <>
            <span className="buttonContent">{this.props.children}</span>
            {this.props.hasArrow ? (
              <svg
                class="Button--button__arrowIcon--ny3GQ"
                width="12"
                height="14"
                viewBox="0 0 12 14"
              >
                <path
                  fill="currentColor"
                  d="M5.17 10V.17h2V10l3.24-3.24 1.42 1.41-5.66 5.66-1.41-1.42L.51 8.17l1.42-1.41z"
                />
              </svg>
            ) : null}
          </>
        ) : (
          '@TODO Add Button Content'
        )}
      </Link>
    ) : (
      <button
        role={this.props.role}
        type={this.props.type}
        className={
          `button-outer ${this.props.hasArrow ? 'w-arrow ' : ''}` +
          'button-style-primary ' +
          'button-style-' +
          this.props.color +
          (this.props.className ? ' ' + this.props.className : '')
        }
      >
        {this.props.children ? (
          <>
            <span className="buttonContent">{this.props.children}</span>
            {this.props.hasArrow ? (
              <svg
                class="Button--button__arrowIcon--ny3GQ"
                width="12"
                height="14"
                viewBox="0 0 12 14"
              >
                <path
                  fill="currentColor"
                  d="M5.17 10V.17h2V10l3.24-3.24 1.42 1.41-5.66 5.66-1.41-1.42L.51 8.17l1.42-1.41z"
                />
              </svg>
            ) : null}
          </>
        ) : (
          '@TODO Add Button Content'
        )}
      </button>
    )
  }
}

export default Button
