import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import EstimateForm from './estimate-form'
import Popup from 'reactjs-popup'
import Logo from '../components/logo-svg'
import Button from '../components/button'
import { Menu, X } from 'react-feather'

import { useLocation } from '@reach/router'

let windowWidth = 0
if (typeof window !== `undefined`) {
  windowWidth = window.innerWidth
}

const MainNavigation = ({ mainNavigation, level = 0 }) => {
  const location = useLocation()

  const menuItems = mainNavigation.map((menuItem, index) => {
    const isParentOnSubPage =
      level == 0 &&
      menuItem.submenu &&
      // first submenu
      menuItem.submenu[0].menuItems.filter(
        ({ subMenuSection, url: subMenuURL }) => {
          const exactPageMatch = subMenuURL == location.pathname
          const userIsOnSubmenuPage =
            subMenuSection &&
            subMenuSection[0].menuItems.filter(({ url }) => {
              //
              let lastPathDirectoryIndex
              let isBlog = false
              try {
                let path = location.pathname
                  .split('/')
                  .filter(path => path !== '')
                lastPathDirectoryIndex = path.slice(-1)[0]
                isBlog = subMenuURL.includes(path[0])
                const exactSubMenuPath = isBlog
                return (
                  // exactSubMenuPath ||
                  subMenuURL.includes(lastPathDirectoryIndex) ||
                  lastPathDirectoryIndex.includes(subMenuURL)
                )
              } catch (e) {
                return false
              }
            }).length > 0
          return exactPageMatch || userIsOnSubmenuPage
        }
      ).length > 0

    return (
      <li
        key={index}
        className={
          'menu-item' +
          (menuItem.submenu ? ' has-submenu ' : '') +
          (menuItem && menuItem.cssClasses ? ' ' + menuItem.cssClasses : '')
        }
      >
        <Link
          to={menuItem.url ? menuItem.url : '#'}
          title={menuItem.title}
          activeClassName={'active'}
          partiallyActive={menuItem.url != '/'}
          className={[
            isParentOnSubPage ? 'active' : '',
            level !== 0 && menuItem.url == location.pathname
              ? 'highlighted'
              : '',
          ].join(' ')}
        >
          {menuItem.name}
        </Link>

        {/* render submenu */}
        {menuItem && menuItem.submenu ? (
          <ul className="submenu submenu-group bg-color-primary-lg">
            <MainNavigation
              mainNavigation={menuItem.submenu[0].menuItems}
              level={level + 1}
            />
          </ul>
        ) : (
          ''
        )}
      </li>
    )
  })
  return menuItems
}

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.toggleMobileMenuOverlay = this.toggleMobileMenuOverlay.bind(this)
    this.state = { headerOpen: false }
  }

  componentDidMount() {
    this.updatePhoneLoadScrollReveal()
  }
  componentDidUpdate() {
    this.updatePhoneLoadScrollReveal()
  }

  updatePhoneLoadScrollReveal = () => {
    window.CallTrk.swap()
  }

  toggleMobileMenuOverlay = () => {
    this.setState({
      headerOpen: (this.headerOpen = !this.headerOpen),
    })

    if (document.body.classList.contains('mobile-menu-overlay--open')) {
      document.body.classList.remove('mobile-menu-overlay--open')
    } else {
      document.body.classList.add('mobile-menu-overlay--open')
    }
  }

  render() {
    const CallToAction = props => {
      return (
        <div className="cta-section">
          <div className="headeroneph">
            <span className="givecalltoday">
              Give Us A Call Today!
              <br />
            </span>
            <a
              href={'tel:' + this.props.companyInfo.phone}
              className="phone-number-header headerphone color-primary hover:color-primary-dark"
            >
              {this.props.companyInfo.phone}
            </a>
          </div>

          <Popup
            trigger={
              <button
                title={'Get Pricing →'}
                style={{ borderWidth: '0' }}
                className="border-radius button-style-primary button-style-secondary headerbutton button-style-white-outline border-0 cursor-pointer primary cta-button"
              >
                Get Pricing →
              </button>
            }
            modal
            closeOnDocumentClick
          >
            {close => (
              <div className="p-2 block">
                <button
                  style={{ borderWidth: '0', float: 'right' }}
                  className="cursor-pointer d-block border-radius mt-3 mr-4 p-2 float-right m0-auto m-0-auto button__ButtonOuter-gFbSyU"
                  onClick={() => {
                    close()
                  }}
                >
                  <X color={'#333'} strokeWidth={'3'} className="d-block" />
                </button>
                <div className="p-3 py-4 block">
                  <h2
                    className="mb-1 popupheading"
                    style={{ lineHeight: '1.05' }}
                  >
                    Request A Free Estimate!
                  </h2>
                  <p
                    style={{
                      color: '#666',
                      lineHeight: '1.40',
                      fontSize: '.9em',
                    }}
                    className="mb-3 popupdesc"
                  >
                    Solid Construction &amp; Design will be happy to visit with
                    you and provide a FREE in-home estimate. You’ll get a truly
                    professional consultation for your dream home improvement
                    project. To schedule a consultation, please complete the
                    information below.
                  </p>
                  <EstimateForm
                    showServicesList={true}
                    showLabels={true}
                    showPlaceHolders={true}
                    pageLocation={
                      props.pageProps.location.href
                        ? props.pageProps.location.href
                        : 'Not provided'
                    }
                  />
                </div>
              </div>
            )}
          </Popup>
        </div>
      )
    }

    return (
      <>
        <div className="container-fluid header-outer-container bg-color-light bg-color-primary-under-992">
          <div className="container padding-0">
            <div className="row gap-6 space-between header">
              <div className="logo-container d-flex align-items-center">
                <Link to="/" style={{ lineHeight: '0' }}>
                  <div
                    className="logo-svg"
                    alt={this.props.siteTitle + ' Logo'}
                  >
                    <Logo />
                  </div>
                </Link>
              </div>

              {this.props.siteDescription ? (
                <div className="site-tagline divcentervertical d-xl-flex flex flex-row hidden lg:divide-x divide-x-0 md:block custom-md">
                  <div className="md:pr-7">
                    <span className="block text-base font-bold">
                      4,500 Sq. Ft. Showroom
                    </span>
                    <span className="block text-xs">
                      5201 Auburn Blvd, Sacramento, CA
                    </span>
                  </div>
                  <div className="md:pl-7">
                    <span className="block text-base font-bold">
                      Office Hours
                    </span>
                    <span className="block text-xs">Mon-Fri: 9AM-4:30PM</span>
                  </div>
                </div>
              ) : null}

              <div className="d-none d-lg-flex align-items-center  justify-content-end">
                <CallToAction {...this.props} />
              </div>

              <div className="menu-mobile d-lg-none d-xl-none d-flex align-items-center justify-content-end">
                <button
                  className="mobile-burger-icon"
                  onClick={this.toggleMobileMenuOverlay}
                >
                  {this.state.headerOpen ? (
                    <>
                      <X size={30} color={'#FFF'} />
                      <span className="sr-only">Close Menu</span>
                    </>
                  ) : (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 24 24"
                        fill="#fff"
                        stroke="#fff"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <line x1="5" y1="12" x2="23" y2="12"></line>
                        <line x1="2" y1="6" x2="23" y2="6"></line>
                        <line x1="8" y1="18" x2="23" y2="18"></line>
                      </svg>
                      <span className="sr-only">Open Menu</span>
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>

          <div className="site-tagline mobileareaserved container padding-0">
            <p>{this.props && this.props.siteDescription}</p>
          </div>
        </div>

        <nav className="nav-outer-container menu-desktop d-none d-lg-flex align-items-center  bg-color-primary">
          <div className="container">
            <ul>
              <MainNavigation
                mainNavigation={this.props.mainNavigation}
                refCallback={this.refCallback}
              />
            </ul>
          </div>
        </nav>

        <div className="sticky-container">
          <div className="secondary-cta-mobile d-lg-none d-xl-none text-color-primary">
            <div className="container">
              <CallToAction
                {...this.props}
                className="d-lg-none d-xl-none col-6 d-flex align-items-center"
              />
            </div>
          </div>
        </div>

        <div
          className={'mobileOverlay' + (this.state.headerOpen ? ' open' : '')}
        >
          <div className="animate-overlay-content">
            <ul className="mobile-navigation-list">
              <MainNavigation mainNavigation={this.props.mainNavigation} />
            </ul>
          </div>
        </div>
      </>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  siteDescription: PropTypes.string,
  mainNavigation: PropTypes.array,
}

Header.defaultProps = {
  siteTitle: '',
  siteDescription: '',
  mainNavigation: '',
}

export default Header
