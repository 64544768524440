import React from 'react'

const logos = {
  default: [
    {
      src: '/assets/logos/starmark.svg',
      title: 'Starmark Cabinetry',
    },
    {
      src: '/assets/logos/fieldstone.svg',
      title: 'Fieldstone Cabinetry',
    },
    {
      src: '/assets/logos/kohler.svg',
      title: 'Kohler',
    },
    {
      src: '/assets/logos/moen.svg',
      title: 'Moen',
      style: {
        padding: '0 5px',
      },
    },
    {
      src: '/assets/logos/caesarstone.svg',
      title: 'Caesarstone Quartz',
    },
    {
      src: '/assets/logos/silestone.svg',
      title: 'Silestone Quartz',
    },
    {
      src: '/assets/logos/viatera.png',
      title: 'LG Viatera',
      style: {
        padding: '0px 10px',
      },
    },
    {
      src: '/assets/logos/cambria.svg',
      title: 'Cambria',
      style: {
        padding: '0px 10px',
      },
    },
  ],
  kitchenRemodeleing: [
    {
      src: '/assets/logos/starmark.svg',
      title: 'Starmark Cabinetry',
    },
    {
      src: '/assets/logos/fieldstone.svg',
      title: 'Fieldstone Cabinetry',
    },
    {
      src: '/assets/logos/kohler.svg',
      title: 'Kohler',
    },
    {
      src: '/assets/logos/moen.svg',
      title: 'Moen',
      style: {
        padding: '0 5px',
      },
    },
    {
      src: '/assets/logos/caesarstone.svg',
      title: 'Caesarstone Quartz',
    },
    {
      src: '/assets/logos/silestone.svg',
      title: 'Silestone Quartz',
    },
    {
      src: '/assets/logos/viatera.png',
      title: 'LG Viatera',
      style: {
        padding: '0px 10px',
      },
    },
    {
      src: '/assets/logos/cambria.svg',
      title: 'Cambria',
      style: {
        padding: '0px 10px',
      },
    },
  ],
  bathroomRemodeling: [
    {
      src: '/assets/logos/kohler.svg',
      title: 'Kohler',
    },
    {
      src: '/assets/logos/toto.svg',
      title: 'Toto',
    },
    {
      src: '/assets/logos/moen.svg',
      title: 'Moen',
      style: {
        padding: '0 5px',
      },
    },
    {
      src: '/assets/logos/blanco.svg',
      title: 'Blanco',
    },
    {
      src: '/assets/logos/silestone.svg',
      title: 'Silestone Quartz',
    },
    {
      src: '/assets/logos/caesarstone.svg',
      title: 'Caesarstone Quartz',
    },
    {
      src: '/assets/logos/viatera.png',
      title: 'LG Viatera',
      style: {
        padding: '0px 10px',
      },
    },
    {
      src: '/assets/logos/piedrafina.svg',
      title: 'Piedrafina',
    },
  ],
}

const renderShowcase = footerLogoShowcase => {
  switch (footerLogoShowcase) {
    case 'kitchenremodeling':
      return logos.kitchenRemodeleing
    case 'bathroomremodeling':
      return logos.bathroomRemodeling

    default:
      return logos.default
  }
}

const ProductLogoShowcase = footerLogoShowcase => {
  const items = renderShowcase(footerLogoShowcase.footerLogoShowcase)
  return (
    <div className="p-logo-showcase-container">
      <div className="container">
        <div className="logo-sc-container-inner">
          {items.map
            ? items.map(({ src, title, style }) => (
                <div className="logo-item">
                  <img
                    src={src}
                    title={title}
                    alt={title}
                    loading="lazy"
                    draggable={false}
                    style={{ ...style, userSelect: 'none' }}
                  />
                </div>
              ))
            : items}
        </div>
      </div>
    </div>
  )
}

export default ProductLogoShowcase
