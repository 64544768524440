import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Header from './header'
import Footer from './footer'
import ProductLogoShowcase from './product-logo-showcase'
import './layout.css'
import '../fonts/fonts.css'
import "../styles/index.scss"
import "../styles/custom.scss"

import { adRefCity } from '../helpers/adRefTracking'


const LayoutContainer = styled.div`

`

const Layout = ({ children, pageProps, footerLogoShowcase, isHomePage }) => {
  //capture initial landing URL & date
  if (
    typeof window !== 'undefined' &&
    window.localStorage &&
    !window.localStorage.getItem('landingURL')
  ) {
    window.localStorage.setItem('landingURL', pageProps.location.href)
    window.localStorage.setItem('landingDate', new Date())
  }
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              description
              companyInfo {
                phone
                email
                addressLine1
                addressLine2
                city
                state
                zip
                license
              }
            }
          }
          allContentfulNavigation(filter: { name: { eq: "Main Navigation" } }) {
            edges {
              node {
                id
                name
                menuItems {
                  id
                  name
                  title
                  url
                  openInNewWindow
                  submenu {
                    id
                    name
                    menuItems {
                      id
                      name
                      title
                      url
                      openInNewWindow
                    }
                  }
                
                }
              }
            }
          }
        }
      `}
        render={data => (
          <>
            <Header
              siteTitle={data.site.siteMetadata.title}
              siteDescription={adRefCity(data.site.siteMetadata.description)}
              companyInfo={data.site.siteMetadata.companyInfo}
              mainNavigation={
                data.allContentfulNavigation.edges[0].node.menuItems
              }
              pageProps={pageProps}
            />
            <LayoutContainer>{children}</LayoutContainer>
            <ProductLogoShowcase footerLogoShowcase={footerLogoShowcase} />

            <Footer isHomePage={isHomePage} />
            
          </>
        )}
      />
    )
  }
Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout