const cityTable = {
  "1013524": "Acton",
  "1013525": "Adelanto",
  "1013526": "Agoura Hills",
  "1013527": "Alameda",
  "1013528": "Alamo",
  "1013529": "Albany",
  "1013530": "Albion",
  "1013531": "Alhambra",
  "1013532": "Aliso Viejo",
  "1013533": "Alleghany",
  "1013534": "Alpaugh",
  "1013535": "Alpine",
  "1013536": "Alta",
  "1013537": "Altadena",
  "1013539": "Alturas",
  "1013541": "American Canyon",
  "1013542": "Anaheim",
  "1013543": "Anderson",
  "1013544": "Angels Camp",
  "1013545": "Angwin",
  "1013546": "Annapolis",
  "1013547": "Antelope",
  "1013548": "Antioch",
  "1013549": "Apple Valley",
  "1013550": "Aptos",
  "1013551": "Arbuckle",
  "1013552": "Arcadia",
  "1013553": "Arcata",
  "1013554": "Arnold",
  "1013555": "Aromas",
  "1013556": "Arroyo Grande",
  "1013557": "Artesia",
  "1013558": "Arvin",
  "1013559": "Atascadero",
  "1013560": "Atherton",
  "1013561": "Atwater",
  "1013562": "Auberry",
  "1013563": "Auburn",
  "1013564": "Avalon",
  "1013565": "Avenal",
  "1013566": "Avila Beach",
  "1013567": "Azusa",
  "1013568": "Badger",
  "1013569": "Baker",
  "1013570": "Bakersfield",
  "1013571": "Baldwin Park",
  "1013572": "Bangor",
  "1013573": "Banning",
  "1013574": "Barstow",
  "1013575": "Beale Air Force Base",
  "1013576": "Beaumont",
  "1013577": "Bell",
  "1013578": "Bell Gardens",
  "1013579": "Bella Vista",
  "1013580": "Bellflower",
  "1013581": "Belmont",
  "1013582": "Belvedere Tiburon",
  "1013583": "Ben Lomond",
  "1013584": "Benicia",
  "1013585": "Berkeley",
  "1013586": "Bethel Island",
  "1013587": "Beverly Hills",
  "1013588": "Big Bear",
  "1013589": "Big Bear Lake",
  "1013590": "Big Bend",
  "1013591": "Big Creek",
  "1013592": "Groveland-Big Oak Flat",
  "1013593": "Big Pine",
  "1013594": "Big Sur",
  "1013595": "Biggs",
  "1013596": "Bishop",
  "1013598": "Bloomington",
  "1013599": "Blue Jay",
  "1013600": "Blue Lake",
  "1013601": "Blythe",
  "1013602": "Bodega Bay",
  "1013603": "Bolinas",
  "1013605": "Bonsall",
  "1013606": "Boonville",
  "1013607": "Boron",
  "1013608": "Borrego Springs",
  "1013609": "Boulder Creek",
  "1013610": "Boyes Hot Springs",
  "1013611": "Bradley",
  "1013612": "Brawley",
  "1013613": "Brea",
  "1013614": "Brentwood",
  "1013615": "Bridgeport",
  "1013617": "Brisbane",
  "1013618": "Brookdale",
  "1013620": "Buellton",
  "1013621": "Buena Park",
  "1013622": "Burbank",
  "1013623": "Burlingame",
  "1013624": "Burney",
  "1013625": "Burrel",
  "1013626": "Butte City",
  "1013627": "Buttonwillow",
  "1013628": "Byron",
  "1013629": "Cabazon",
  "1013630": "Calabasas",
  "1013631": "Calexico",
  "1013632": "California City",
  "1013633": "California Hot Springs",
  "1013634": "Calimesa",
  "1013635": "Calipatria",
  "1013636": "Calistoga",
  "1013637": "Calpella",
  "1013638": "Camarillo",
  "1013639": "Cambria",
  "1013640": "Camino",
  "1013641": "Camp Pendleton North",
  "1013642": "Campbell",
  "1013643": "Campo",
  "1013644": "Camptonville",
  "1013646": "Canyon",
  "1013649": "Capitola",
  "1013651": "Carlsbad",
  "1013652": "Carmel-by-the-Sea",
  "1013653": "Carmel Valley",
  "1013654": "Carmichael",
  "1013655": "Carpinteria",
  "1013656": "Carson",
  "1013657": "Caruthers",
  "1013658": "Castaic",
  "1013659": "Castro Valley",
  "1013660": "Castroville",
  "1013661": "Cathedral City",
  "1013662": "Cayucos",
  "1013663": "Cazadero",
  "1013665": "Ceres",
  "1013666": "Cerritos",
  "1013669": "Chester",
  "1013670": "Chicago Park",
  "1013671": "Chico",
  "1013672": "Chinese Camp",
  "1013673": "Chino",
  "1013674": "Chino Hills",
  "1013675": "Chowchilla",
  "1013676": "Chualar",
  "1013677": "Chula Vista",
  "1013678": "Citrus Heights",
  "1013679": "Industry",
  "1013680": "Claremont",
  "1013681": "Clayton",
  "1013682": "Clearlake",
  "1013683": "Clearlake Oaks",
  "1013684": "Clements",
  "1013685": "Cloverdale",
  "1013686": "Clovis",
  "1013687": "Coachella",
  "1013688": "Coalinga",
  "1013689": "Cobb",
  "1013690": "Coleville",
  "1013691": "Colfax",
  "1013692": "Coloma",
  "1013693": "Colton",
  "1013694": "Columbia",
  "1013695": "Colusa",
  "1013696": "Compton",
  "1013697": "Concord",
  "1013698": "Copperopolis",
  "1013699": "Corcoran",
  "1013700": "Corning",
  "1013701": "Corona",
  "1013703": "Coronado",
  "1013704": "Corte Madera",
  "1013705": "Costa Mesa",
  "1013706": "Cottonwood",
  "1013707": "Coulterville",
  "1013708": "Covelo",
  "1013709": "Covina",
  "1013710": "Crescent City",
  "1013711": "Crestline",
  "1013712": "Crockett",
  "1013713": "Crows Landing",
  "1013714": "Culver City",
  "1013715": "Cupertino",
  "1013716": "Cypress",
  "1013717": "Daggett",
  "1013718": "Daly City",
  "1013719": "Dana Point",
  "1013720": "Danville",
  "1013721": "Davis",
  "1013722": "Death Valley",
  "1013723": "Deer Park",
  "1013724": "Del Mar",
  "1013725": "Delano",
  "1013726": "Delhi",
  "1013727": "Del Rio",
  "1013728": "Desert Center",
  "1013730": "Diablo",
  "1013731": "Diamond Bar",
  "1013732": "Diamond Springs",
  "1013733": "Dinuba",
  "1013734": "Dixon",
  "1013735": "Dorris",
  "1013736": "Dos Palos",
  "1013737": "Downey",
  "1013738": "Downieville",
  "1013739": "Duarte",
  "1013740": "Dublin",
  "1013741": "Ducor",
  "1013742": "Dunnigan",
  "1013743": "Dunsmuir",
  "1013744": "Durham",
  "1013745": "Dutch Flat",
  "1013746": "Earlimart",
  "1013747": "Earp",
  "1013749": "Edison",
  "1013751": "El Cajon",
  "1013752": "El Centro",
  "1013753": "El Cerrito",
  "1013755": "El Dorado Hills",
  "1013756": "El Granada",
  "1013757": "El Monte",
  "1013758": "El Segundo",
  "1013759": "El Sobrante",
  "1013760": "Eldridge",
  "1013761": "Elk",
  "1013762": "Elk Creek",
  "1013763": "Elk Grove",
  "1013764": "Elmira",
  "1013765": "Elverta",
  "1013766": "Emeryville",
  "1013767": "Empire",
  "1013768": "Encinitas",
  "1013770": "Escalon",
  "1013771": "Escondido",
  "1013772": "Esparto",
  "1013773": "Etna",
  "1013774": "Eureka",
  "1013775": "Exeter",
  "1013776": "Fair Oaks",
  "1013777": "Fairfax",
  "1013778": "Fairfield",
  "1013779": "Fall River Mills",
  "1013780": "Fallbrook",
  "1013781": "Farmersville",
  "1013782": "Farmington",
  "1013783": "Felton",
  "1013784": "Ferndale",
  "1013785": "Fiddletown",
  "1013786": "Fillmore",
  "1013787": "Firebaugh",
  "1013788": "Five Points",
  "1013789": "Folsom",
  "1013790": "Fontana",
  "1013792": "Forbestown",
  "1013793": "Forest Knolls",
  "1013794": "Forest Ranch",
  "1013795": "Foresthill",
  "1013796": "Forestville",
  "1013797": "Fortuna",
  "1013798": "Fountain Valley",
  "1013799": "Fowler",
  "1013800": "Frazier Park",
  "1013801": "Freedom",
  "1013802": "Fremont",
  "1013803": "French Gulch",
  "1013804": "Fresno",
  "1013805": "Friant",
  "1013806": "Fort Bragg",
  "1013807": "Fort Irwin",
  "1013808": "Fullerton",
  "1013809": "Fulton",
  "1013810": "Galt",
  "1013811": "Garberville",
  "1013812": "Garden Grove",
  "1013813": "Gardena",
  "1013814": "Gazelle",
  "1013815": "Georgetown",
  "1013816": "Geyserville",
  "1013817": "Gilroy",
  "1013818": "Glen Ellen",
  "1013819": "Glendale",
  "1013820": "Glendora",
  "1013821": "Glennville",
  "1013822": "Goleta",
  "1013823": "Gonzales",
  "1013825": "Grand Terrace",
  "1013826": "Grass Valley",
  "1013828": "Greenfield",
  "1013829": "Greenville",
  "1013830": "Greenwood",
  "1013831": "Grenada",
  "1013832": "Gridley",
  "1013833": "Grizzly Flats",
  "1013834": "Groveland",
  "1013835": "Grover Beach",
  "1013836": "Guadalupe",
  "1013837": "Gualala",
  "1013838": "Guerneville",
  "1013839": "Guinda",
  "1013840": "Gustine",
  "1013841": "Hacienda Heights",
  "1013842": "Half Moon Bay",
  "1013843": "Hanford",
  "1013844": "Happy Camp",
  "1013846": "Hawthorne",
  "1013847": "Hayfork",
  "1013848": "Hayward",
  "1013849": "Healdsburg",
  "1013850": "Heber",
  "1013851": "Helendale",
  "1013852": "Hemet",
  "1013853": "Herald",
  "1013854": "Hercules",
  "1013855": "Herlong",
  "1013856": "Hermosa Beach",
  "1013857": "Hesperia",
  "1013858": "Hickman",
  "1013859": "Highland",
  "1013860": "Hilmar",
  "1013861": "Hollister",
  "1013862": "Holtville",
  "1013863": "Homeland",
  "1013864": "Homewood",
  "1013865": "Honeydew",
  "1013866": "Hoopa",
  "1013867": "Hornbrook",
  "1013868": "Hughson",
  "1013869": "Huntington Beach",
  "1013870": "Huntington Park",
  "1013871": "Huron",
  "1013872": "Hydesville",
  "1013874": "Igo",
  "1013875": "Imperial",
  "1013876": "Imperial Beach",
  "1013877": "Independence",
  "1013878": "Indian Wells",
  "1013879": "Indio",
  "1013880": "Inglewood",
  "1013881": "Inverness",
  "1013882": "Ione",
  "1013883": "Irvine",
  "1013884": "Isleton",
  "1013885": "Ivanhoe",
  "1013886": "Jackson",
  "1013887": "Jacumba Hot Springs",
  "1013888": "Jamestown",
  "1013889": "Jamul",
  "1013890": "Jenner",
  "1013891": "Joshua Tree",
  "1013892": "Julian",
  "1013893": "June Lake",
  "1013894": "Kelseyville",
  "1013895": "Kentfield",
  "1013896": "Kenwood",
  "1013897": "Kerman",
  "1013898": "Kernville",
  "1013899": "King City",
  "1013900": "Kings Beach",
  "1013901": "Kings Canyon National Park",
  "1013902": "Kingsburg",
  "1013903": "Klamath",
  "1013904": "Kneeland",
  "1013905": "Knights Landing",
  "1013906": "Knightsen",
  "1013907": "La Canada Flintridge",
  "1013908": "La Crescenta-Montrose",
  "1013909": "La Habra",
  "1013910": "La Honda",
  "1013912": "La Mesa",
  "1013913": "La Mirada",
  "1013914": "La Palma",
  "1013915": "La Puente",
  "1013916": "La Quinta",
  "1013917": "La Verne",
  "1013918": "Ladera Ranch",
  "1013919": "Lafayette",
  "1013920": "Laguna Beach",
  "1013921": "Laguna Hills",
  "1013922": "Laguna Niguel",
  "1013923": "Lake Arrowhead",
  "1013924": "Lake Elsinore",
  "1013925": "Lake Forest",
  "1013926": "Lake Hughes",
  "1013927": "Lake Isabella",
  "1013928": "Lakeport",
  "1013929": "Lakeside",
  "1013930": "Lakewood",
  "1013931": "Lancaster",
  "1013932": "Larkspur",
  "1013933": "Lathrop",
  "1013934": "Laton",
  "1013935": "Lawndale",
  "1013936": "Laytonville",
  "1013937": "Le Grand",
  "1013938": "Lebec",
  "1013939": "Lee Vining",
  "1013940": "Leggett",
  "1013941": "Lemon Cove",
  "1013942": "Lemon Grove",
  "1013943": "Lemoore",
  "1013944": "Lewiston",
  "1013945": "Lincoln",
  "1013946": "Linden",
  "1013947": "Lindsay",
  "1013948": "Littlerock",
  "1013949": "Live Oak",
  "1013950": "Livermore",
  "1013951": "Livingston",
  "1013952": "Lockeford",
  "1013953": "Lodi",
  "1013954": "Loma Linda",
  "1013955": "Lomita",
  "1013956": "Lompoc",
  "1013957": "Lone Pine",
  "1013958": "Long Beach",
  "1013959": "Loomis",
  "1013960": "Los Alamitos",
  "1013961": "Los Altos",
  "1013962": "Los Angeles",
  "1013963": "Los Banos",
  "1013964": "Los Gatos",
  "1013965": "Los Molinos",
  "1013966": "Los Olivos",
  "1013968": "Lost Hills",
  "1013969": "Lower Lake",
  "1013970": "Loyalton",
  "1013971": "Lucerne",
  "1013972": "Lucerne Valley",
  "1013973": "Lynwood",
  "1013974": "Lyoth",
  "1013975": "Mad River",
  "1013976": "Madera",
  "1013977": "Magalia",
  "1013978": "Malibu",
  "1013979": "Mammoth Lakes",
  "1013980": "Manhattan Beach",
  "1013981": "Manteca",
  "1013982": "March Air Reserve Base",
  "1013983": "Marina",
  "1013984": "Marina del Rey",
  "1013985": "Mariposa",
  "1013986": "Markleeville",
  "1013987": "Marshall",
  "1013988": "Martinez",
  "1013989": "Marysville",
  "1013990": "Mather",
  "1013991": "Maxwell",
  "1013992": "Maywood",
  "1013993": "McFarland",
  "1013994": "McKittrick",
  "1013995": "McClellan Park",
  "1013996": "McKinleyville",
  "1013997": "Meadow Vista",
  "1013998": "Mecca",
  "1013999": "Mendocino",
  "1014000": "Mendota",
  "1014001": "Menifee",
  "1014002": "Menlo Park",
  "1014003": "Mentone",
  "1014004": "Merced",
  "1014005": "Meridian",
  "1014006": "Mi-Wuk Village",
  "1014007": "Middletown",
  "1014008": "Midway City",
  "1014009": "Mill Valley",
  "1014010": "Millbrae",
  "1014011": "Millville",
  "1014012": "Milpitas",
  "1014013": "Mira Loma",
  "1014014": "Miramonte",
  "1014015": "Miranda",
  "1014017": "Mission Viejo",
  "1014018": "Moccasin",
  "1014019": "Modesto",
  "1014020": "Mojave",
  "1014021": "Mokelumne Hill",
  "1014022": "Monrovia",
  "1014023": "Montague",
  "1014024": "Montara",
  "1014025": "Montclair",
  "1014026": "Monte Rio",
  "1014027": "Montebello",
  "1014028": "Monterey",
  "1014029": "Monterey Park",
  "1014031": "Moorpark",
  "1014032": "Moraga",
  "1014033": "Moreno Valley",
  "1014034": "Morgan Hill",
  "1014035": "Morro Bay",
  "1014036": "Moss Beach",
  "1014037": "Moss Landing",
  "1014039": "Mount Baldy",
  "1014040": "Mount Hamilton",
  "1014041": "Mount Laguna",
  "1014042": "Mount Shasta",
  "1014043": "Mount Wilson",
  "1014044": "Mountain View",
  "1014045": "Murrieta",
  "1014046": "Napa",
  "1014047": "National City",
  "1014048": "Navarro",
  "1014049": "Needles",
  "1014050": "Nevada City",
  "1014051": "New Cuyama",
  "1014052": "Newark",
  "1014053": "Newberry Springs",
  "1014055": "Newcastle",
  "1014057": "Newman",
  "1014058": "Newport Beach",
  "1014060": "Nicasio",
  "1014061": "Nicolaus",
  "1014062": "Nipomo",
  "1014063": "Norco",
  "1014064": "Norden",
  "1014065": "North Fork",
  "1014066": "North Highlands",
  "1014069": "Desert Hot Springs",
  "1014070": "North San Juan",
  "1014072": "Norwalk",
  "1014073": "Novato",
  "1014074": "Nuevo",
  "1014075": "O'Neals",
  "1014076": "Oak Park",
  "1014077": "Oak View",
  "1014078": "Oakdale",
  "1014079": "Oakhurst",
  "1014080": "Oakland",
  "1014081": "Oakley",
  "1014082": "Oakville",
  "1014083": "Occidental",
  "1014084": "Oceano",
  "1014085": "Oceanside",
  "1014086": "Ojai",
  "1014087": "Olancha",
  "1014088": "Olympic Valley",
  "1014089": "Ontario",
  "1014090": "Orange",
  "1014092": "Orangevale",
  "1014093": "Orinda",
  "1014094": "Orland",
  "1014095": "Orleans",
  "1014096": "Oro Grande",
  "1014097": "Orosi",
  "1014098": "Oroville",
  "1014099": "Oxnard",
  "1014100": "Pacific Grove",
  "1014102": "Pacifica",
  "1014105": "Pala",
  "1014106": "Palm Desert",
  "1014107": "Palm Springs",
  "1014108": "Palmdale",
  "1014109": "Palo Alto",
  "1014110": "Palo Cedro",
  "1014111": "Palomar Mountain",
  "1014112": "Palos Verdes Peninsula",
  "1014114": "Paradise",
  "1014115": "Paramount",
  "1014116": "Parker Dam",
  "1014117": "Parlier",
  "1014118": "Pasadena",
  "1014119": "Paskenta",
  "1014120": "Paso Robles",
  "1014121": "Patterson",
  "1014122": "Pearblossom",
  "1014124": "Penn Valley",
  "1014125": "Penngrove",
  "1014126": "Penryn",
  "1014127": "Perris",
  "1014128": "Pescadero",
  "1014129": "Petaluma",
  "1014130": "Petrolia",
  "1014131": "Phelan",
  "1014132": "Phillipsville",
  "1014133": "Pico Rivera",
  "1014134": "Piedmont",
  "1014135": "Piercy",
  "1014136": "Pine Grove",
  "1014137": "Pine Valley",
  "1014138": "Pinecrest",
  "1014139": "Pinole",
  "1014140": "Pismo Beach",
  "1014141": "Pittsburg",
  "1014142": "Pixley",
  "1014143": "Placentia",
  "1014144": "Placerville",
  "1014145": "Planada",
  "1014147": "Pleasant Grove",
  "1014148": "Pleasant Hill",
  "1014149": "Pleasanton",
  "1014150": "Plymouth",
  "1014151": "Point Arena",
  "1014152": "Naval Air Station Point Mugu",
  "1014153": "Point Reyes Station",
  "1014154": "Pollock Pines",
  "1014155": "Pomona",
  "1014156": "Port Costa",
  "1014157": "Port Hueneme",
  "1014158": "Naval Construction Battalion Center Port Hueneme",
  "1014159": "Porterville",
  "1014160": "Portola",
  "1014161": "Portola Valley",
  "1014162": "Poway",
  "1014163": "Quincy",
  "1014164": "Raisin City",
  "1014166": "Rancho Cordova",
  "1014167": "Rancho Cucamonga",
  "1014168": "Rancho Mirage",
  "1014169": "Rancho Palos Verdes",
  "1014170": "Rancho Santa Fe",
  "1014171": "Rancho Santa Margarita",
  "1014172": "Randsburg",
  "1014173": "Raymond",
  "1014174": "Red Bluff",
  "1014175": "Redding",
  "1014176": "Redlands",
  "1014177": "Redondo Beach",
  "1014178": "Redwood City",
  "1014180": "Redwood Valley",
  "1014181": "Reedley",
  "1014183": "Rescue",
  "1014185": "Rialto",
  "1014186": "Richgrove",
  "1014187": "Richmond",
  "1014188": "Richvale",
  "1014189": "Ridgecrest",
  "1014190": "Rio Dell",
  "1014191": "Rio Linda",
  "1014192": "Rio Oso",
  "1014193": "Rio Vista",
  "1014194": "Ripon",
  "1014195": "Riverbank",
  "1014196": "Riverdale",
  "1014197": "Riverside",
  "1014198": "Robbins",
  "1014199": "Rocklin",
  "1014200": "Rodeo",
  "1014201": "Rohnert Park",
  "1014202": "Rosamond",
  "1014203": "Rosemead",
  "1014204": "Roseville",
  "1014205": "Ross",
  "1014206": "Rough and Ready",
  "1014207": "Rowland Heights",
  "1014208": "Sacramento",
  "1014209": "Salida",
  "1014210": "Salinas",
  "1014211": "San Andreas",
  "1014212": "San Anselmo",
  "1014213": "San Ardo",
  "1014214": "San Bernardino",
  "1014215": "San Bruno",
  "1014216": "San Carlos",
  "1014217": "San Clemente",
  "1014218": "San Diego",
  "1014219": "San Dimas",
  "1014220": "San Fernando",
  "1014221": "San Francisco",
  "1014222": "San Gabriel",
  "1014223": "San Gregorio",
  "1014224": "San Jacinto",
  "1014225": "San Joaquin",
  "1014226": "San Jose",
  "1014227": "San Juan Bautista",
  "1014228": "San Juan Capistrano",
  "1014229": "San Leandro",
  "1014230": "San Lorenzo",
  "1014231": "San Lucas",
  "1014232": "San Luis Obispo",
  "1014234": "San Marcos",
  "1014235": "San Marino",
  "1014236": "San Martin",
  "1014237": "San Mateo",
  "1014238": "San Miguel",
  "1014239": "San Pablo",
  "1014241": "San Quentin",
  "1014242": "San Rafael",
  "1014243": "San Ramon",
  "1014244": "San Simeon",
  "1014246": "Sanger",
  "1014247": "Santa Ana",
  "1014248": "Santa Barbara",
  "1014249": "Santa Clara",
  "1014250": "Santa Clarita",
  "1014251": "Santa Cruz",
  "1014252": "Santa Fe Springs",
  "1014253": "Santa Margarita",
  "1014254": "Santa Maria",
  "1014255": "Santa Monica",
  "1014256": "Santa Paula",
  "1014257": "Santa Rosa",
  "1014258": "Santa Ynez",
  "1014259": "Santee",
  "1014260": "Saratoga",
  "1014261": "Sausalito",
  "1014262": "Scotts Valley",
  "1014263": "Seal Beach",
  "1014264": "Seaside",
  "1014265": "Sebastopol",
  "1014266": "Selma",
  "1014267": "Sequoia National Park",
  "1014268": "Shafter",
  "1014269": "Shandon",
  "1014270": "Shasta",
  "1014271": "Shasta Lake",
  "1014272": "Sheep Ranch",
  "1014274": "Shingle Springs",
  "1014275": "Shoshone",
  "1014276": "Sierra Madre",
  "1014277": "Sierraville",
  "1014278": "Silverado",
  "1014279": "Simi Valley",
  "1014280": "Sloughhouse",
  "1014281": "Smartsville",
  "1014282": "Snelling",
  "1014283": "Soda Springs",
  "1014284": "Solana Beach",
  "1014285": "Soledad",
  "1014286": "Solvang",
  "1014287": "Somis",
  "1014288": "Sonoma",
  "1014289": "Sonora",
  "1014290": "Soquel",
  "1014291": "South El Monte",
  "1014292": "South Gate",
  "1014293": "South Lake Tahoe",
  "1014294": "South Pasadena",
  "1014295": "South San Francisco",
  "1014296": "Spreckels",
  "1014297": "Spring Valley",
  "1014298": "Springville",
  "1014299": "Squaw Valley",
  "1014300": "St. Helena",
  "1014301": "Stanford",
  "1014302": "Stanton",
  "1014303": "Stevenson Ranch",
  "1014304": "Stevinson",
  "1014305": "Stinson Beach",
  "1014306": "Stockton",
  "1014307": "Stonyford",
  "1014308": "Strathmore",
  "1014310": "Suisun City",
  "1014311": "Sultana",
  "1014315": "Sunnyvale",
  "1014316": "Sunol",
  "1014318": "Susanville",
  "1014319": "Sutter",
  "1014320": "Sutter Creek",
  "1014322": "Taft",
  "1014323": "Tahoe City",
  "1014324": "Tahoe Vista",
  "1014326": "Tehachapi",
  "1014327": "Temecula",
  "1014328": "Temple City",
  "1014329": "Templeton",
  "1014330": "Thermal",
  "1014331": "Thornton",
  "1014332": "Thousand Oaks",
  "1014333": "Thousand Palms",
  "1014334": "Three Rivers",
  "1014335": "Tipton",
  "1014336": "Tollhouse",
  "1014337": "Tomales",
  "1014338": "Topanga",
  "1014339": "Torrance",
  "1014340": "Trabuco Canyon",
  "1014341": "Tracy",
  "1014342": "Tranquillity",
  "1014343": "Traver",
  "1014344": "Travis Air Force Base",
  "1014346": "Truckee",
  "1014348": "Tulare",
  "1014349": "Tulelake",
  "1014350": "Tupman",
  "1014351": "Turlock",
  "1014352": "Tustin",
  "1014353": "Twain Harte",
  "1014354": "Twentynine Palms",
  "1014355": "Twin Peaks",
  "1014356": "Ukiah",
  "1014357": "Union City",
  "1014358": "Universal City",
  "1014359": "Upland",
  "1014360": "Upper Lake",
  "1014361": "Vacaville",
  "1014363": "Vallejo",
  "1014364": "Valley Center",
  "1014365": "Valley Springs",
  "1014368": "Venice",
  "1014369": "Ventura",
  "1014370": "Victorville",
  "1014371": "Vidal",
  "1014372": "Villa Park",
  "1014373": "Visalia",
  "1014374": "Vista",
  "1014375": "Wallace",
  "1014376": "Walnut",
  "1014377": "Walnut Creek",
  "1014378": "Warner Springs",
  "1014379": "Wasco",
  "1014380": "Washington",
  "1014381": "Waterford",
  "1014382": "Watsonville",
  "1014383": "Waukena",
  "1014384": "Weaverville",
  "1014385": "Weed",
  "1014386": "Weldon",
  "1014387": "Weott",
  "1014388": "West Covina",
  "1014390": "West Hollywood",
  "1014391": "West Sacramento",
  "1014392": "Westlake Village",
  "1014393": "Westley",
  "1014394": "Westminster",
  "1014395": "Westmorland",
  "1014396": "Westwood",
  "1014397": "Wheatland",
  "1014398": "Whitethorn",
  "1014399": "Whittier",
  "1014400": "Wildomar",
  "1014401": "Williams",
  "1014402": "Willits",
  "1014403": "Willow Creek",
  "1014404": "Willows",
  "1014406": "Wilton",
  "1014407": "Winchester",
  "1014408": "Windsor",
  "1014410": "Winterhaven",
  "1014411": "Winters",
  "1014412": "Woodacre",
  "1014413": "Woodbridge",
  "1014414": "Woodlake",
  "1014415": "Woodland",
  "1014417": "Wrightwood",
  "1014418": "Yermo",
  "1014419": "Yolo",
  "1014420": "Yorba Linda",
  "1014421": "Yosemite Valley",
  "1014422": "Yountville",
  "1014423": "Yreka",
  "1014424": "Yuba City",
  "1014425": "Yucaipa",
  "1014426": "Yucca Valley",
  "1018531": "California",
  "1020233": "California",
  "1024525": "New Pine Creek",
  "1024709": "California",
  "9051507": "Alta Sierra",
  "9051519": "Arden-Arcade",
  "9051533": "Avocado Heights",
  "9051556": "Bay Point",
  "9051561": "Baywood-Los Osos",
  "9051601": "Blackhawk",
  "9051618": "Bonita",
  "9051682": "Cameron Park",
  "9051684": "Camp Pendleton South",
  "9051689": "Canyon Lake",
  "9051759": "Commerce",
  "9051775": "Cotati",
  "9051776": "Coto de Caza",
  "9051815": "Del Monte Forest",
  "9051835": "Discovery Bay",
  "9051870": "East Whittier",
  "9051873": "East Los Angeles",
  "9051877": "East Palo Alto",
  "9051887": "Eastvale",
  "9051895": "Edwards Air Force Base",
  "9051898": "El Camino Village",
  "9051954": "Florence-Graham",
  "9051956": "Florin",
  "9051959": "Foothill Farms",
  "9051977": "Foster City",
  "9052004": "Furnace Creek",
  "9052035": "Gold River",
  "9052048": "Granite Bay",
  "9052052": "Graton",
  "9052104": "Happy Valley",
  "9052114": "Hawaiian Gardens",
  "9052127": "Hidden Meadows",
  "9052136": "Hillsborough",
  "9052177": "Idyllwild-Pine Cove",
  "9052191": "Irwindale",
  "9052192": "Isla Vista",
  "9052196": "Janesville",
  "9052251": "La Riviera",
  "9052259": "Laguna Woods",
  "9052270": "Lake of the Pines",
  "9052326": "Linda",
  "9052336": "Live Oak",
  "9052350": "Los Altos Hills",
  "9052358": "Lucas Valley-Marinwood",
  "9052467": "Montecito",
  "9052561": "North Tustin",
  "9052592": "Oildale",
  "9052597": "Olivehurst",
  "9052601": "Orange Cove",
  "9052604": "Orcutt",
  "9052672": "Pine Mountain Club",
  "9052718": "Prunedale",
  "9052725": "Rainbow",
  "9052727": "Rancho Calaveras",
  "9052728": "Rancho Murieta",
  "9052729": "Rancho San Diego",
  "9052771": "Rosemont",
  "9052775": "Rossmoor",
  "9052850": "Signal Hill",
  "9052888": "South Whittier",
  "9052956": "Tamalpais-Homestead Valley",
  "9053039": "Valle Vista",
  "9053046": "Vandenberg Air Force Base",
  "9053056": "Vineyard",
  "9053107": "West Carson",
  "9053123": "West Point",
  "9053124": "West Rancho Dominguez",
  "9053170": "Willowbrook",
  "9053199": "Woodcrest",
  "9053208": "Woodside",
  "9053226": "Yosemite Lakes",
  "9073453": "Blairsden",
  "9073454": "Graeagle",
  "9073455": "Challenge-Brownsville",
  "9073456": "South Los Angeles",
  "9073459": "Ramona",
  "9032465": "North Highlands",
  

};

export const adRefCity = def => {
  //for webpack
  try {

    const adRef = JSON.parse(window.sessionStorage.getItem('rFprAn'))
    if (!adRef || (adRef && !adRef.locphysical)) {
      return def
    }
    let stringBase = 'Serving '
    const cityName = cityTable[adRef.locphysical]
    if(!cityName) {
      return def
    }
    return stringBase + cityName + ", CA and surrounding areas"
  } catch(e) {
    return def;
  }
}

export const getValueTrackParams = () => {
  try {
    const { city, locphysical, campaigns } = JSON.parse(
      window.sessionStorage.getItem('rFprAn')
    )
    return { city, locphysical, campaigns }
  } catch (e) {
    return {}
  }
}
  